import './App.css';
import { Button, Container, Grid, styled} from "@mui/material";
import { useTranslation } from 'react-i18next';

export const App = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const {t} = useTranslation()
    const click_id = urlParams.get('click_id')
    const sum = urlParams.get('cost')
    const camp_id = urlParams.get('camp_id')

    return (
        <div className="App">
            <div className={'Card'}>
                <ContainerStyled className={'maxWidthSm mx-40 sm:px-1'}>
                    <Grid className={'sm:pt-12'} container width={'100%'} justifyContent={'center'} height={'100%'}>
                        <Grid item xs={12} sm={10} md={10} lg={10} overflow={'hidden'}
                              className={'flex justify-center'}>
                            <div
                                className={'flex flex-col items-center relative h-[480px] -mx-5 w-full lg:h-[520px] lg:max-w-[420px] lg:mx-auto lg:mt-0 pb-4'}>
                                <img
                                    className={'sm:rounded-lg w-full h-full'}
                                    style={{ objectFit: 'cover'}}
                                    src={'https://nomadlifeblog.com/main.webp'}/>
                                <div className={'absolute w-full top-0 h-[480px] lg:h-[520px]'}>
                                    <div className={'absolute bottom-0 w-full h-36'}
                                         style={{background: 'linear-gradient(rgba(0, 0, 0, 0) 0%, rgb(246, 199, 221) 100%)'}}/>
                                    <div className={'absolute bottom-0 w-full h-24'}
                                         style={{background: 'linear-gradient(rgba(0, 0, 0, 0) 0%, rgb(246, 199, 221) 100%)'}}/>
                                </div>
                            </div>

                        </Grid>
                        <Grid item xs={10}>
                            <span className={'text-2xl text-pink-400'}>{t('title')}</span>
                            <center>
                                <StyledButton href={`https://meetsweetsoulmate.life/?u=2fnk60g&o=y8z813l&cid=${click_id}&sum=${sum}&t=${camp_id}`} className={'pulse'}>
                                    <div className={'w-full min-w-0 whitespace-pre-wrap text-center font-bold'}>
                                        {t('yes')} 🍓
                                    </div>
                                </StyledButton>
                                <StyledButton disabled href={'asd'} sx={{backgroundColor: 'gray'}}>
                                    <div className={'w-full min-w-0 whitespace-pre-wrap text-center'}>
                                        {t("no")}
                                    </div>
                                </StyledButton>
                            </center>
                        </Grid>
                    </Grid>
                </ContainerStyled>

            </div>
        </div>
    )
}

const ContainerStyled = styled(Container)(() => ({
    paddingBottom: '100px',
    marginLeft: 'auto',
    height: '100%',
    paddingLeft: 0,
    paddingRight: 0
}));


const StyledButton = styled(Button)(() => ({
    borderRadius: '36px',
    backgroundColor: 'rgb(199 67 117 / 70%)',
    color: 'rgb(255, 255, 255)',
    fontFamily: 'Poppins, sans-serif',
    textTransform: 'none',
    boxShadow: 'none',
    fontSize: '15px',
    minHeight: '65px',
    padding: '8px',
    margin: '16px 0px',
    border: '2px solid rgba(255, 255, 255, 0)',
    display: 'flex',
    pointerEvents: 'auto',
    '&:hover': {
        backgroundColor: 'rgb(199, 67, 117)',
    }
}))
