import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {initReactI18next} from "react-i18next";
import {App} from './App';
import i18n from 'i18next';
import enTranslation from './locales/en.json';
import frTranslation from './locales/fr.json';
import deTranslation from './locales/de.json';
import ruTranslation from './locales/ru.json';
import itTranslation from './locales/it.json';
import esTranslation from './locales/es.json';
import ptTranslation from './locales/pt.json';
import heTranslation from './locales/he.json';
import daTranslation from './locales/da.json';
import elTranslation from './locales/el.json';
import slTranslation from './locales/sl.json';
import noTranslation from './locales/no.json';
import etTranslation from './locales/et.json';
import ltTranslation from './locales/lt.json';
import svTranslation from './locales/sv.json';
import nlTranslation from './locales/nl.json';

const userLanguage = navigator.language || navigator.userLanguage;

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: enTranslation },
            fr: { translation: frTranslation },
            de: { translation: deTranslation },
            ru: { translation: ruTranslation },
            kk: { translation: ruTranslation },
            uk: { translation: ruTranslation },
            it: { translation: itTranslation },
            es: { translation: esTranslation },
            pt: { translation: ptTranslation },
            he: { translation: heTranslation },
            da: { translation: daTranslation },
            el: { translation: elTranslation },
            sl: { translation: slTranslation },
            no: { translation: noTranslation },
            et: { translation: etTranslation },
            lt: { translation: ltTranslation },
            sv: { translation: svTranslation },
            nl: { translation: nlTranslation },
        },
        lng: userLanguage, // Устанавливаем язык пользователя
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        }
    });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
